import ItemTile from './itemTile.js'
import css from './store.module.css';
import { getItems } from '../store_admin/admin_actions.js';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


export function Store({defaultCategory}) {
  const location = useLocation()
  const category = location.state?.category || defaultCategory;
  const [items, setItems] = useState([])
  const isFeatured = !location.state?.category && defaultCategory === 'featured';

  useEffect(() => {
    pullItems()
  }, [category]);

  const pullItems = () => {
    getItems().then((data) => {
      let filteredItems;
      if (isFeatured) {
        filteredItems = data.filter(item => item.featured_checked);
      } else {
        filteredItems = data.filter(item => item.category === category);
      }
      setItems(filteredItems);
    });
  };

  const createItems = items.map((item) => {
    if (item.qty_small > 0 || item.qty_medium > 0 || item.qty_large > 0 || item.qty_extra_large > 0) {
      if (item.label !== "2024") {}
      return (
        <ItemTile
          key={item.id}
          id={item.id}
          title={item.title}
          description={item.description}
          price={item.price}
          category={item.category}
          colour={item.colour}
          image1={item.image1}
          image2={item.image2}
          image3={item.image3}
          sale={item.sale}
          label={item.label}
          qty_small={item.qty_small}
          qty_medium={item.qty_medium}
          qty_large={item.qty_large}
          qty_extra_large={item.qty_extra_large}
          featured_checked={item.featured_checked}
          mode="store"
          inventory="yes"
        />
      );
    }
    return null;
  }).filter(Boolean);

  const capitalise = () => {
    const categoryToCapitalize = location.state?.category || defaultCategory;
    if (!categoryToCapitalize || categoryToCapitalize === 'featured') return ''; 
    return categoryToCapitalize.charAt(0).toUpperCase() + categoryToCapitalize.slice(1);
  }

return (
    <div className={`${isFeatured ? css.store_container_featured : css.store_container}`}>
      {createItems.length > 0 ? (
        <div className={css.store_item_cont}>
          {createItems}
        </div>
      ) : (
        !isFeatured && <div className={css.store_title}>Soon to Come</div>
      )}
    </div>
  );
}



export function StoreSelect() {

   const navigate = useNavigate();

   return (
      <div className={css.container}>
         <div className={css.store_category_cont}>
            <div className={css.store_category} onClick={()=> navigate('/store', {state: {category: 'tops'} })}>Tops</div>
            <div className={css.store_category} onClick={()=> navigate('/store', {state: {category: 'bottoms'} })}>Bottoms</div>
            <div className={css.store_category} onClick={()=> navigate('/store', {state: {category: 'hats'} })}>Hats</div>
            <div className={css.store_category} onClick={()=> navigate('/store', {state: {category: 'accessories'} })}>Accessories</div>
         </div>
        <Store defaultCategory='featured'/>
      </div>
  );
}



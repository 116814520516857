import css from './gallery.module.css';
import { useNavigate } from 'react-router-dom';


export default function Gallery() {

  const navigate = useNavigate()

  const images = [
    { txt: "Streetwear enthusiast tori models at our recent photoshoot in front of the notorious stained glass of the Brangwyn hall. captured by luke baker photography", 
      src: "https://d3plr6xnj3tfvw.cloudfront.net/Core/G1.jpeg", 
      title: "Tori Models in Iconic Brangwyn Hall" },
    { txt: "‘One needed clothing enterprise we connect to every local g from around the south west’ night photoshoot taken in the presence of Once and Swansea's creative rapper - LastRoe. After some editing, from luke baker photography, this became the next album cover, for spotify. Last roe can be found sharing bars about swansea's finest clothing brand Onceuk", 
      src: "https://d3plr6xnj3tfvw.cloudfront.net/Core/G2.jpeg", 
      title: "LastRoe's Album Cover Photoshoot by Luke Baker" },
    { txt: "2022 marked the date of Mumbles fresh concrete skatepark, one of the incremental steps to rebuilding the skateboard scene in Swansea. Once has had strong ties in the skate scene since its establishment in 2020. Louis promotes local talent of all ages. Compilations of this talent can be found on youtube (check our home page). ", 
      src: "https://d3plr6xnj3tfvw.cloudfront.net/Core/G3.jpeg", 
      title: "Once Supports Swansea's Skateboard Scene" },
    { txt: "Once upon a time down langland bay, luke baker photography needed new content for his college portfolio, so he called us up, and we shot a promotion for ONCE - killing two birds with one stone.", 
      src: "https://d3plr6xnj3tfvw.cloudfront.net/Core/G4.jpeg", 
      title: "Langland Bay Shoot" },
    { txt: "A favorable shot from a recent group photoshoot promoting the 2023 collection.", 
      src: "https://d3plr6xnj3tfvw.cloudfront.net/Core/G5.jpeg", 
      title: "2023 Collection Group Photoshoot Highlight" },
    { txt: "A shot by luke baker photography in the early days of mumbles skatepark, built by maverick who also built many memorable parks such as concrete waves. Kieran Drake, our once representative, can be found in our most recent skate video on youtube.", 
      src: "https://d3plr6xnj3tfvw.cloudfront.net/Core/G6.jpeg", 
      title: "Early Days of Mumbles Skatepark Captured" },
    { txt: "Louis has been promoting his clothes since 2020. Also helping many creative individuals along the way, in areas of; music, skateboarding, graphic design, spray painting and more.", 
      src: "https://d3plr6xnj3tfvw.cloudfront.net/Core/G7.jpeg", 
      title: "Louis Promotes Creativity and Streetwear" },
    { txt: "An interesting first ever photoshoot, located around swansea city center. The models and I found an intriguing spray paint wall and had a wonderful experience.", 
      src: "https://d3plr6xnj3tfvw.cloudfront.net/Core/G8.jpeg", 
      title: "First Photoshoot with Swansea Street Art" },
    { txt: "In the spur of the moment on a gloomy day, the once team turned up to help model the latest stock back in September 2023. This was a pivotal time, where ONCE invested in a new drop - the hoodies and streetwear tracksuits can be found in this photo. Shots by luke baker photography", 
      src: "https://d3plr6xnj3tfvw.cloudfront.net/Core/G9.jpeg", 
      title: "ONCE's September 2023 Collection Photoshoot" },
    { txt: "An iconic photoshoot that has captured both our longsleeve spell out tees and gilet in a perfect way. A favourable photo from an evening spent witht he communtiy", 
      src: "https://d3plr6xnj3tfvw.cloudfront.net/Core/G10.jpeg", 
      title: "Evening Shoot Featuring Tees and Gilet" },
  ];

  return (
    <div className={css.gallery_container}>
      <div className={css.image_grid}>
        {images.map((image, index) => (
          <div key={index} className={css.image_item} data-title={image.title}
               onClick={()=> navigate('/gallery_preview', {state: {img_src: image.src, img_txt: image.txt,} })}>
            <img src={image.src} alt={image.title} />
          </div>
        ))}
      </div>
      <div className={css.return_button}
           onClick={()=> navigate('/')} > Return to Homepage </div>
    </div>
  );
}
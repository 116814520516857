import css from './tile.module.css';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { StateContext } from '../state_management/context.js'
import axios from "axios";


export default function ItemTile(props) {

  const navigate = useNavigate(props);
  const [isHovered, setIsHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  var img_int = 1


  useEffect(() => {
    if (props.mode == "admin") {
      if (props.inventory == "no") {
        set_tile_size_toggle(`${css.tile_no_inventory} ${css.coming_soon}`);
      } else {
        set_tile_size_toggle(css.tile_admin);
      }
      set_admin_button_toggle(css.admin_btn_cont);
      shuffle();
    } else if (props.mode == "store") {
      set_image_class_1(css.image);
      if (props.inventory == "no") {
        set_tile_size_toggle(`${css.tile} ${css.coming_soon}`);
      }
    } else if (props.mode == "checkout") {
      set_checkout_button_toggle(css.checkout_del_btn);
      set_image_class_1(css.image);
    }
  }, []);

    const shuffle = () => {
      if( img_int == 1 ) {
        img_int = 2
        set_image_class_1(css.image)
        set_image_class_2(css.display_none)
        set_image_class_3(css.display_none)
      }else if( img_int == 2 ) {
        img_int = 3
        set_image_class_2(css.image)
        set_image_class_1(css.display_none)
        set_image_class_3(css.display_none)
      }else {
        img_int = 1
        set_image_class_3(css.image)
        set_image_class_1(css.display_none)
        set_image_class_2(css.display_none)
      }
     setTimeout(shuffle, 3500);
    }

    const [state, dispatch] = useContext(StateContext)

    const [image_class_1, set_image_class_1] = useState(css.display_none)
    const [image_class_2, set_image_class_2] = useState(css.display_none)
    const [image_class_3, set_image_class_3] = useState(css.display_none)

    const [admin_button_toggle, set_admin_button_toggle] = useState(css.display_none)
    const [checkout_button_toggle, set_checkout_button_toggle] = useState(css.display_none)
    const [tile_size_toggle, set_tile_size_toggle] = useState(css.tile)
    const [sale_tag, set_sale_tag] = useState(css.display_none)
    const [sale_amount, set_sale_amount] = useState(props.sale)
    const [sale_price, set_sale_price] = useState(props.price)
    const [sale_container, set_sale_container] = useState(css.sale_container)
    const [price_no_sale, set_price_no_sale] = useState(css.price_no_sale)


  const mouseOverTile = event => {
    set_image_class_1(css.display_none)
    set_image_class_2(`${css.image} ${css.image_cursor}`)
  }

  const mouseLeaveTile = event => {
    set_image_class_2(css.display_none)
    set_image_class_1(`${css.image} ${css.image_cursor}`)
  }

  const handleTileClick = (e) => {
    if (props.inventory === "no") {
      e.preventDefault();
      e.stopPropagation();
      setShowModal(true);
    } else if (props.mode === "store") {
      navigate('/item_preview', {state: {item:props}});
    }
  };

  const editPopulate = (item) => {
    navigate('/store_admin', {state:{item:item,editSwitch:1,delSwitch:0}})
  }

  const deletePopulate = (item) => {
    navigate('/store_admin', {state:{item:item,editSwitch:0,delSwitch:1}})
  }

  const saveData = (key, data) => {
    window.localStorage.setItem(key, JSON.stringify(data));
  };

   const handleNotifyClick = (e) => {
    e.stopPropagation(); // Prevent click from propagating to parent elements
    setShowModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the email to your backend
    console.log(`Notification request for email: ${email}`);
    setShowModal(false)
    closeModal()
    email_notification(email, props.title)
  };

  const closeModal = () => {
    setShowModal(false);
    setEmail('');
  };

  const removeFromBasket = (item) => {
    for (let i = 0; i < state.count; i++) {
      const items = state.items
      const item_id = items[i].id
      if(item_id == props.id) {
        items.splice(i, 1)
        saveData('basket', items);
        dispatch({ type: 'remove_from_basket', payload: items })
        break
      }
    }
  }

  const title_length_check = (title) => {
      if( title.length > 25 ){
         return css.title_two_lines
      } else {
         return css.title
      }
   }

  const title_length_check_for_sale = (title) => {
      if( title.length > 25  && props.sale > 0){
        if( props.mode == 'admin' ) {
          return css.sale_tag_admin_two_lines
         }else {
          return css.sale_tag_two_lines
         }
      } else if(props.sale > 0){
         return sale_tag
      } else {
        return css.display_none
      }
   }

  const email_notification = async ( email, item ) => {
    const response = await axios.post('/api/mail/send_email/', 
                    { 'subject': 'A customer is excited about an upcoming drop!',
                      'recipient': '1nceuk.clothing@gmail.com',
                      'message': 'Customer Email: ' + email + '; Item:' + props.title })}

  return (
    <div className={tile_size_toggle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
         onClick={handleTileClick} >
      <div className={css.tile_content}>
        <div className={title_length_check_for_sale(props.title)}>{sale_amount}% Sale</div>
        <img className={image_class_1} src={props.image1} 
             onMouseOver={() => { if (props.mode === "store" || props.mode === "checkout") { mouseOverTile() }}} />
        <img className={image_class_2} src={props.image2}  
             onMouseLeave={() => { if (props.mode === "store" || props.mode === "checkout") { mouseLeaveTile()}}} />

          <div className={css.detailBox}> 
            <div className={title_length_check(props.title)}>{props.title}</div>
            <div className={sale_container}>
              <div className={css.price}>{"£" + props.price}</div>
              <div className={css.price_sale}>{"£" + sale_price}</div>
            </div>
            <div className={price_no_sale}>{"£" + props.price}</div>
          </div>

          <div className={admin_button_toggle}>
            <div className={css.deleteTile} onClick={(e) => { e.stopPropagation(); deletePopulate(props); }}/>
            <div className={css.editTile} onClick={(e) => { e.stopPropagation(); editPopulate(props); }}/>
          </div>
          <div className={checkout_button_toggle}>
            <div className={css.checkout_deleteTile} onClick={(e) => { e.stopPropagation(); removeFromBasket(props); }}/>
          </div>

          {props.inventory === "no" && (
          <div className={`${css.notify_overlay} ${isHovered ? css.hovered : ''}`}>
            <div className={css.notify_text} onClick={handleNotifyClick}>Get notified when this drops</div>
          </div>
          )}

          </div>
          {showModal && (
            <div className={css.modal_overlay} onClick={() => setShowModal(false)}>
              <div className={css.modal_content} onClick={e => e.stopPropagation()}>
              <button className={css.close_button} onClick={closeModal}>×</button>
                <h3>Get Notified</h3>
                <form onSubmit={handleSubmit}>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <button type="submit">Get Notified</button>
                </form>
              </div>
            </div>
          )}
        </div>
      );
}


import Carousel from './carousel.js';
import Circles from './circles.js';
import Collections from './collections.js';
import css from './home.module.css';
import { useNavigate } from 'react-router-dom';


export default function Home() {

  const navigate = useNavigate()

   return (
    <div className={css.home_container}>
      <div className={css.home_banner}> One Needed Clothing Enterprise UK </div>
      <Carousel />
      <div className={css.home_banner}> Join the Community </div>
      <div className={css.shop_now_button} onClick={()=> navigate('/store_selection')}>Shop Now</div>
      <Circles />
      <Collections />
    </div>
  );
}


import css from './home.module.css';
import { useState } from "react";

export default function Carousel() {
  const images = [
    "https://d3plr6xnj3tfvw.cloudfront.net/Core/new_carousel_1.jpeg",
    "https://d3plr6xnj3tfvw.cloudfront.net/Core/new_carousel_2.jpeg",
    "https://d3plr6xnj3tfvw.cloudfront.net/Core/new_carousel_3.jpeg",
    "https://d3plr6xnj3tfvw.cloudfront.net/Core/new_carousel_4.jpeg",
    "https://d3plr6xnj3tfvw.cloudfront.net/Core/new_carousel_5.jpeg",
    "https://d3plr6xnj3tfvw.cloudfront.net/Core/new_carousel_6.jpeg",
    "https://d3plr6xnj3tfvw.cloudfront.net/Core/new_carousel_7.jpeg"
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleSelectorClick = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <>
      <div className={css.carousel_cont}>
        <img 
          src={images[currentImageIndex]} 
          className={css.carousel_img_style} 
          alt={`Carousel image ${currentImageIndex + 1}`} 
        />
      </div>
      <div className={css.carousel_sel_cont}>
        <div className={css.carousel_sel_cont_2}>
          {images.slice(0, 6).map((image, index) => (
            <img 
              key={index}
              className={css.carousel_icon} 
              src={images[index]} 
              onClick={() => handleSelectorClick(index)}
            />
          ))}
        </div>
      </div>
    </>
  );
}
import css from './home.module.css';
import { useNavigate } from 'react-router-dom';

export default function Collections() {
  const navigate = useNavigate();
  return (
    <div className={css.collections_container_0}>
      <div className={css.collections_container_1}>
        <div className={css.collection_tile}>
          <img 
            className={css.collection_img} 
            src='https://d3plr6xnj3tfvw.cloudfront.net/Core/2023_img.jpeg'
            onClick={() => navigate('/2023')}
            alt="2023 Collection"
          />
          <div className={css.collection_text}>2023</div>
        </div>
        <div className={css.crown_container}>
          <img 
            className={css.crown_gif} 
            src='https://d3plr6xnj3tfvw.cloudfront.net/Core/crown_gif.gif'
            onClick={() => navigate('/login', { switch: 0 })}
            alt="Crown"
          />
        </div>
        <div className={css.collection_tile}>
          <img 
            className={css.collection_img} 
            src='https://d3plr6xnj3tfvw.cloudfront.net/Core/2024_img.jpeg'
            onClick={() => navigate('/2024')}
            alt="2024 Collection"
          />
          <div className={css.collection_text}>2024</div>
        </div>
      </div>
    </div>
  );
}
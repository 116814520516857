import css from './learn_more.module.css';
import { useNavigate } from 'react-router-dom';


export default function LearnMore() {

  const navigate = useNavigate()

  return (
    <div className={css.learn_more_container}>
      
      <div className={css.headings}> WHAT DOES ONCE OFFER? </div>
        <div className={css.text}> 
          High-Quality Products at Lower Costs: We believe that quality shouldn’t come with a hefty price tag. We value our products, hence why we are partnering with top tier manufacturers that provide for big-name brands. this allows us to offer these premium items without the luxury price tags. We want to make top-tier skate and streetwear accessible to everyone. 
          As for our designs: we've partnered with Professional artists, graphic designers and like-minded creative individuals from across Wales. This means, since the beginning of our journey, we have  helped Welsh artists manifest their creative ideas, turning them into quality clothes they are proud of wearing. Some creative partners used their content in their college assessment. So apparent that once is here for the community, fostering meaningful relationships is at our core. 
          As once grows to ‘one needed clothing enterprise - uk’ we invite you to be the next designer. get your art featured on garments, for you and your friends to wear. Get in contact with us to find out if you are eligible selections for our community curated clothing project. 
          As we grow, our catalog and creativity will expand, providing more opportunities for your creativity. Moving forward, we plan to expand this network, outside of Wales - new designers, skaters, music artists, content creators. You are invited to contact us for new opportunities and help. Become a part of the once family today. As our team grows, our skills and capabilities expand, ensuring we will remain ‘one needed clothing enterprise’ in the United kingdom for years to come.
        </div>

      <div className={css.headings}> WHAT MAKES US DIFFERENT? </div>
        <div className={css.text}>
          Welcome to One needed clothing enterprise. . We are more than another inexperienced clothing brand saturating the market with low quality garments. Our focus does not lie in fast fashion or quick money. ONCE has been a community project, specifying quality streetwear and skateboarding inspired attire. 
          Since 2021, so far many of our products feature designs crafted by talented artists from across Swansea. We’ve built a strong network of local creatives who have supported us since we began. In 2021 and 2022, we hosted several competitions where these artists competed to create unique graphics for our t-shirt releases. Each design was distinctive, incorporating our iconic purple crown in innovative ways. 
          From these competition entries we found an exciting amount of talent unrecognised if our brand did not help.  We wanted to showcase their talent and strengthen community ties. Our lucky winners received free prizes, including a t-shirt featuring their design and a spot in the next giveaway. Many have even used their t-shirt designs in their college portfolios, making us a true reflection of our community’s talent  
          In 2024, we are taking bold steps to develop our own distinctive style, away from the competitions and to embrace greater creative freedom with professional artists - offering you that higher value product, This transition allows a new era for ONCE.
        </div>

      <div className={css.headings}> WHY BUY FROM US? </div>
        <div className={css.text}>
          As we grow, we can offer more styles and unique customizations, made possible with the support of our community and customers. When you purchase from us, you have the option to join our email list. By joining, you'll receive exclusive discounts, access to unique items, and connections with talented individuals and businesses, through our community events.
          Our email list ensures you're welcomed into a network of creative minds with diverse skills. You'll be the first to know about new releases, competitions, projects, and more, keeping you at the forefront of our evolving brand.
        </div>

      <div className={css.headings}> JOIN OUR COMMUNITY </div>
        <div className={css.text}>
          Did you know that ONCE was one of the first skateboard clothing brands in Swansea? We started with t-shirts designed for skaters and fashion enthusiasts. Our early success saw our hoodies becoming a staple in every skatepark and school around Swansea.
          Whether you’re here to support our cause, seek sponsorship, or become part of a thriving community, ONCE can help you achieve a greater purpose in your creative career. Our mission has always been to connect people and foster relationships through our clothing.
          By choosing ONCE, you’re not just buying clothes; you’re joining a family of creative minds who represent the purple crown. Together, we can create a long lasting impact on the fashion and skate scene of uk - Join us and be a part of something greater
        </div>

      <div className={css.return_button}
         onClick={()=> navigate('/')} > Return to Homepage </div>
    </div>
  );
}


import css from './core.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import { StateContext } from '../state_management/context.js'


export function Navbar(item) {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useContext(StateContext)
  const getData = key => {
    return JSON.parse(window.localStorage.getItem(key));
  };
  useEffect(() => {
    const items = getData('basket');
    if( items != null ) {
      dispatch({ type: 'set_basket', payload: items })
    }
  }, []);
  return (
    <div className={css.navbar}>
      <div className={css.navbarContent}>
        <div className={css.logo1nce} onClick={()=> navigate('/')}/>
        <div className={css.storeTitle} onClick={()=> navigate('/store_selection')}>Shop Now</div>
        <div className={css.basketCont}>  
          <div className={css.shopCounter}>{state.count}</div>
          <div className={css.shopBtn} onClick={()=> navigate('/basket')}/>
        </div>
      </div>
    </div>
  );
}

export function Footer() {

  const navigate = useNavigate();

  const toYoutube = () => {
    window.location.href = 'https://www.youtube.com/@1nceuk';
  }

  const toInstagram = () => {
    window.location.href = 'https://www.instagram.com/1nceuk/';
  }

return (
  <div className={css.footer}>
    <div className={css.footerContent}>
      <div className={css.iconTxt} onClick={toInstagram}>Instagram</div>
    	<div className={css.iconTxt} onClick={toYoutube}>YouTube</div>
    	<div className={css.iconTxt} onClick={()=> navigate('/contact')}>Contact Us</div>
    </div>
  </div>
)}


export function Underbar() {

return (
  <div className={css.underbar}/>
)}

export function UnderbarHeader() {

return (
  <div className={css.underbar_header}/>
)}

export function UnderbarFooter() {

return (
  <div className={css.underbar_footer}/>
)}


import css from './gallery.module.css';
import { useLocation, useNavigate } from 'react-router-dom';

export default function GalleryPreview() {

  const location = useLocation()
  const navigate = useNavigate()
  
  return (
    <div className={css.gallery_preview_container}>
      <img className={css.preview_img} src={location.state.img_src} />
      <div className={css.preview_text}> {location.state.img_txt} </div>
      <div className={css.return_button}
           onClick={()=> navigate('/gallery')} > Return to Gallery </div>
    </div>
  );
}
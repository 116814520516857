import css from './home.module.css';
import { useNavigate } from 'react-router-dom';

export default function AboutUsLinks() {

  const navigate = useNavigate();
return (
  <div className={css.circle_container_0}>
    <div className={css.circle_container_1}>
      <div className={css.circle_tile}>
        <img 
          className={css.circle_img} 
          src='https://d3plr6xnj3tfvw.cloudfront.net/Core/learn_more.png' 
          onClick={() => navigate('/learn_more')}
          alt="Learn More"
        />
        <div className={css.circle_text}>Learn More</div>
      </div>
      <div className={css.circle_tile}>
        <img 
          className={css.circle_img}
          src='https://d3plr6xnj3tfvw.cloudfront.net/Core/gallery.png' 
          onClick={() => navigate('/gallery')}
          alt="Gallery"
        />
        <div className={css.circle_text}>Gallery</div>
      </div>
    </div>
  </div>
);
}

